import { theme } from '@/app/theme/theme';
import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { View } from './types';
import { Workout } from '@trainme/db';

export const getZoneColor = (zone: number = 1): string => {
  const zoneKey = `zone-${zone}` as keyof typeof theme.extend.colors;
  const colorValue = theme.extend.colors[zoneKey];
  return colorValue;
};

// not very useful, I'd do `h-full` + ${className} instead.
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getCalendarViewFromPathname(pathname: string) {
  const pathParts = pathname.split('/');
  if (pathParts[1] === 'calendar') {
    return pathParts[2] as View;
  }
  return View.Week;
}

export const defaultWorkout: Workout = {
  id: '',
  name: 'Default Workout',
  date: new Date(),
  description: 'Base run',
  sportType: 'Run',
  distance: null,
  duration: null,
  steps: ['10m Z2 HR Warm up...'],
  feeling: null,
  rpe: null,
  notes: null,
  userId: '',
  createdAt: new Date(),
  updatedAt: new Date(),
};
