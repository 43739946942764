import type { Activity, Analysis, Workout } from '@trainme/db';
import type { Lap } from '@trainme/db';

export type MapField = {
  id: string;
  polyline?: string;
  resourceState: number;
  summaryPolyline?: string;
};

export type ActivityWithLaps = Activity & { laps?: Lap[] };
export type WorkoutWithAnalysis = Workout & { analysis?: Analysis[] };

// The calendar view, lowercase to match the URL params
export enum View {
  Week = 'week',
  Month = 'month',
}
